import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import ArticleCarousel from "../components/articleCarousel";
import { withPreview } from "gatsby-source-prismic";

const IndexPage = (props) => {
  const { data } = props;
  let isimList;

  const [getIsim, setIsim] = useState(
    <div className="jumbotron-content col-md-10 col-md-offset-1">
      <div className="tag tag-teal">
        LEVEL FIVE-ISM No. <span id="jumbotron-number"></span>
      </div>
      <h1 id="jumbotron-quote" className="jumbotron-quote headline"></h1>
    </div>
  );

  useEffect(() => {
    console.log("isim leng", isimList);

    let index = Math.floor(Math.random() * isimList.length);
    console.log("ISIM LIST", isimList[index]);
    console.log("ISism", index);

    setIsim(
      <div className="jumbotron-content col-md-10 col-md-offset-1">
        <div className="tag tag-teal">
          LEVEL FIVE-ISM No. <span id="jumbotron-number">{index + 1}</span>
        </div>
        <h1
          id="jumbotron-quote"
          className="jumbotron-quote headline"
          dangerouslySetInnerHTML={{ __html: isimList[index].ism.text }}
        ></h1>
      </div>
    );
  }, []);

  console.log(">>>", data);
  const doc = data.home.edges[0].node;
  console.log("doc -->", doc);
  if (!doc) return null;

  const home = doc.data;
  const articles = data.articles.edges.filter(
    (article) => article.node.data.include_on_homepage
  );
  const site_wide_cta = data.site_wide_cta.edges;
  isimList = data.isims.edges[0].node.data.isms;
  const social = data.social.edges[0].node.data.social_links;

  return (
    <Layout>
      <Helmet>
        <style>
          {`.thumb { text-align: center; margin-bottom: 20px; max-width: 100%; }
        .grid-home { margin-bottom: 20px; }
        
        @media screen and (min-width: 768px) {
            .slick-carousel-home {
                margin-bottom: 90px;
            }
            .grid-home { margin-bottom: 70px; }
            
            .jumbotron-home-bottom .col-md-6 { text-align: left; }
            .jumbotron-home-bottom .col-md-2 { text-align: right; }
        }`}
        </style>
      </Helmet>
      <SEO
        title={home.meta_title}
        image={home.meta_og_image.url}
        description={home.meta_description}
        canonical={
          typeof window !== "undefined" &&
          window.location.origin + window.location.pathname
        }
      />

      <div className="jumbotron-home bg-home margin-bottom-0" alt="">
        <div className="container">
          <div className="jumbotron-home-top row">
            {getIsim}
            {/* {  selectIsim(isimList) } */}
          </div>
          <div className="jumbotron-home-bottom row">
            <div
              className="col-md-6 col-md-offset-2 beta-light beta home-border"
              dangerouslySetInnerHTML={{ __html: home.tagline_cta_text.html }}
            ></div>
            <div className="col-md-2 margin-top-16">
              <a
                href={home.tagline_cta_link.url}
                target={home.tagline_cta_link.target}
                className="btn btn-primary"
              >
                {home.tagline_cta_link_text}&rarr;
              </a>
            </div>
          </div>
        </div>
      </div>

      <section id="body-area">
        <ArticleCarousel articles={articles} />
        <div className="container">
          <div
            className="txt-center txt-18 txt-gray-50"
            dangerouslySetInnerHTML={{ __html: home.client_section_text.html }}
          ></div>
          <div className="grid grid-home row">
            {home.client_images.map((image, i) => {
              return (
                <div key={i} className="col-md-2 col-xs-6 thumb">
                  {/* <div style={{ height: '55px', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundImage: `url(${image.client_image.url})` }} role="img" aria-label={ image.client_image.url }></div> */}
                  <img
                    src={image.client_image.url}
                    alt={image.client_image.alt}
                    className="width-75p client-image"
                  />
                </div>
              );
            })}
          </div>

          <div className="contact-bar bar">
            <div className="left">
              {social.map((d, i) => {
                return (
                  <a key={i} href={d.social_link.url} target="_blank">
                    <img
                      width="30"
                      src={d.social_icon.url}
                      alt={d.social_icon.alt}
                    />
                    &nbsp;
                  </a>
                );
              })}
            </div>
            <div className="right">
              <a href="../contact-us?tab=other">
                <span className="pin-label left">
                  Driving to the
                  <br />
                  <strong>KC OFFICE?</strong>
                </span>
                <span className="pin-icon icn-pin-kc right">[Map Pin]</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default withPreview(IndexPage);

export const homePageQuery = graphql`
  query homeQuery {
    home: allPrismicHomePage {
      edges {
        node {
          data {
            meta_description
            meta_title
            client_section_text {
              html
            }
            client_section_background {
              alt
              url
            }
            client_images {
              client_image {
                alt
                url
              }
            }
            wide_image {
              alt
              url
            }
            tagline_cta_link_text
            tagline_cta_text {
              html
              text
            }
            tagline_cta_link {
              target
              url
            }
            meta_og_image {
              url
            }
          }
          prismicId
        }
      }
    }
    articles: allPrismicArticle {
      edges {
        node {
          uid
          first_publication_date
          data {
            article_author
            article_body_text {
              html
            }
            article_summary
            article_date
            article_title {
              text
            }
            include_on_homepage
            spot_image {
              alt
              url
            }
            body {
              ... on PrismicArticleBodyCalloutBox {
                id
                primary {
                  callout_box_body_text {
                    html
                  }
                  callout_box_cta_link {
                    url
                    target
                  }
                  callout_box_label {
                    text
                  }
                  callout_box_cta_text
                }
              }
            }
          }
        }
      }
    }
    isims: allPrismicLevelFiveIsms {
      edges {
        node {
          data {
            isms {
              ism {
                html
                text
              }
            }
          }
        }
      }
    }
    site_wide_cta: allPrismicSiteWideCta {
      edges {
        node {
          data {
            site_wide_callout_link {
              url
            }
            site_wide_callout_link_text
            site_wide_callout_text
          }
        }
      }
    }
    social: allPrismicSocialInformation {
      edges {
        node {
          data {
            social_links {
              social_icon {
                alt
                url
              }
              social_link {
                url
              }
              social_name
              social_service
            }
          }
        }
      }
    }
  }
`;
